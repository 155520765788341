import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PharmacySettingsService } from 'src/app/core/services/pharmacy-settings.service';
import { PdfScaffoldComponent } from '../pdf-scaffold/pdf-scaffold.component';
import { ArticlePdfArticleComponent } from './article-pdf-article/article-pdf-article.component';

@Component({
    selector: 'app-article-pdf',
    templateUrl: './article-pdf.component.html',
    imports: [
        PdfScaffoldComponent,
        ArticlePdfArticleComponent,
        TranslateModule,
    ]
})
export class ArticlePdfComponent implements OnChanges {
	@Input() articlesMatrix: any[][] = [[]];
	@Input() marginCheckbox: boolean = false;
	@Input() dispensedArticles: boolean = false;
	pharmacyName: string;
	startDate: Date = new Date();
	endDate: Date = new Date();
	now: Date = new Date();

	constructor(private pharmacySettingsService: PharmacySettingsService) {}

	ngOnChanges(changes: SimpleChanges): void {
		this.now = new Date();
		this.pharmacyName =
			this.pharmacySettingsService.onPharmacySettingsChanged.getValue()?.name;
	}
}
